import React, { useState } from "react";
import {
  TextField,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Link,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";

const PulseDemoPage = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);

  const mockResults = {
    videos: [
      {
        id: 1,
        title: "Morning Workout Routine",
        creator: "@fitnessGuru",
        views: "1.2M",
        engagement: "21%",
        tags: ["fitness", "workout", "morning"],
      },
      {
        id: 2,
        title: "Gaming Setup Tour 2024",
        creator: "@techReviewer",
        views: "800K",
        engagement: "18%",
        tags: ["gaming", "setup", "tech"],
      },
    ],
    products: [
      {
        id: 1,
        name: "Premium Yoga Mat",
        price: "$49.99",
        relevance: "95%",
        tags: ["fitness", "workout"],
        image: "https://picsum.photos/200/200",
        link: "https://example.com/product",
        relatedTikToks: [
          {
            id: 1,
            creator: "@yogamaster",
            title: "Best Yoga Mat Review",
            views: "500K",
            link: "https://tiktok.com/video1",
          },
          {
            id: 2,
            creator: "@fitnessguru",
            title: "Morning Yoga Essentials",
            views: "750K",
            link: "https://tiktok.com/video2",
          },
        ],
      },
      {
        id: 2,
        name: "Gaming Chair",
        price: "$299.99",
        relevance: "87%",
        tags: ["gaming", "furniture"],
        image: "https://picsum.photos/200/200",
        link: "https://example.com/product2",
        relatedTikToks: [
          {
            id: 3,
            creator: "@gamersetup",
            title: "Best Gaming Chairs 2024",
            views: "1.2M",
            link: "https://tiktok.com/video3",
          },
        ],
      },
    ],
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      setResults(mockResults);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f8fafc",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "hidden",
      }}
    >
      {/* Header section */}
      <Box
        sx={{
          p: { xs: 2, sm: 3, md: 4 },
          pb: { xs: 2, sm: 3 },
          backgroundColor: "#fff",
          borderBottom: "1px solid #eaeaea",
        }}
      >
        <Box sx={{ maxWidth: "1400px", mx: "auto" }}>
          <Typography
            variant="h2"
            className="gradient-text"
            sx={{
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3.5rem" },
              fontWeight: 700,
              mb: { xs: 1, sm: 2 },
              fontFamily: "Helvetica, sans-serif",
              textAlign: { xs: "left", sm: "center" },
            }}
          >
            Discover Trends
          </Typography>

          <Typography
            variant="h5"
            sx={{
              color: "#64748b",
              mb: { xs: 2, sm: 4 },
              maxWidth: "800px",
              lineHeight: 1.5,
              fontFamily: "monospace",
              fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
              textAlign: { xs: "left", sm: "center" },
              mx: { xs: 0, sm: "auto" },
            }}
          >
            Using social media video content to predict trending products before
            they go viral.
          </Typography>

          <Box
            component="form"
            onSubmit={handleSearch}
            sx={{
              maxWidth: "800px",
              mx: { xs: 0, sm: "auto" },
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="What are 20-year-old gamers watching?"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  height: { xs: "48px", sm: "56px" },
                  backgroundColor: "#fff",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                  fontFamily: "monospace",
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Content Area */}
      {results && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            height: { xs: "auto", md: "calc(100vh - 280px)" },
            maxWidth: "1400px",
            mx: "auto",
            mt: { xs: 2, md: 3 },
            px: { xs: 2, sm: 3, md: 4 },
            gap: 2,
            overflow: "auto",
          }}
        >
          {/* Left Panel - Videos */}
          <Box
            sx={{
              width: { xs: "100%", md: "65%" },
              backgroundColor: "#fff",
              borderRadius: "12px",
              border: "1px solid #eaeaea",
              overflow: "hidden",
              height: { xs: "auto", md: "100%" },
              minHeight: { xs: "400px", md: "auto" },
            }}
          >
            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                borderBottom: "1px solid #eaeaea",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: 600,
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                <PlayCircleOutlineIcon sx={{ color: "#2563eb" }} />
                Videos They Might Be Watching
              </Typography>
            </Box>

            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                overflowX: "auto",
                overflowY: "auto",
                height: { xs: "auto", md: "calc(100% - 70px)" },
              }}
            >
              <TableContainer>
                <Table
                  sx={{
                    minWidth: 650,
                    "& .MuiTableCell-root": {
                      px: { xs: 1, sm: 2 },
                      py: { xs: 1, sm: 2 },
                      fontSize: { xs: "0.8rem", sm: "0.9rem" },
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, color: "#64748b" }}>
                        Title
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, color: "#64748b" }}>
                        Creator
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, color: "#64748b" }}>
                        Views
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, color: "#64748b" }}>
                        Engagement
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, color: "#64748b" }}>
                        Tags
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {results.videos.map((video) => (
                      <TableRow
                        key={video.id}
                        sx={{ "&:hover": { backgroundColor: "#f8fafc" } }}
                      >
                        <TableCell>{video.title}</TableCell>
                        <TableCell>{video.creator}</TableCell>
                        <TableCell>{video.views}</TableCell>
                        <TableCell>{video.engagement}</TableCell>
                        <TableCell>
                          <Box
                            sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}
                          >
                            {video.tags.map((tag) => (
                              <Chip
                                key={tag}
                                label={tag}
                                size="small"
                                sx={{
                                  backgroundColor: "#f1f5f9",
                                  borderRadius: "4px",
                                  fontFamily: "monospace",
                                }}
                              />
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          {/* Right Panel - Products */}
          <Box
            sx={{
              width: { xs: "100%", md: "35%" },
              backgroundColor: "#fff",
              borderRadius: "12px",
              border: "1px solid #eaeaea",
              overflow: "hidden",
              height: { xs: "auto", md: "100%" },
              minHeight: { xs: "400px", md: "auto" },
            }}
          >
            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                borderBottom: "1px solid #eaeaea",
                backgroundColor: "#fff",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: 600,
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                <ShoppingBagIcon sx={{ color: "#2563eb" }} />
                Trending Product Opportunities
              </Typography>
            </Box>

            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                overflowY: "auto",
                height: { xs: "auto", md: "calc(100% - 70px)" },
                display: "grid",
                gap: 2,
              }}
            >
              {results.products.map((product) => (
                <Card
                  key={product.id}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid #eaeaea",
                    borderRadius: "12px",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{
                          width: 120,
                          height: 120,
                          objectFit: "cover",
                          borderRadius: 8,
                        }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Link
                          href={product.link}
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: "none",
                            color: "inherit",
                            "&:hover": {
                              color: "#2563eb",
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontFamily: "Helvetica, sans-serif" }}
                          >
                            {product.name}
                          </Typography>
                        </Link>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#2563eb",
                            mb: 1,
                            fontFamily: "monospace",
                          }}
                        >
                          {product.price}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#64748b",
                            mb: 1,
                            fontFamily: "monospace",
                          }}
                        >
                          Match Score: {product.relevance}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 0.5, mb: 2 }}>
                          {product.tags.map((tag) => (
                            <Chip
                              key={tag}
                              label={tag}
                              size="small"
                              sx={{
                                backgroundColor: "#f1f5f9",
                                borderRadius: "4px",
                                fontFamily: "monospace",
                              }}
                            />
                          ))}
                        </Box>

                        {/* Related TikToks Section */}
                        {product.relatedTikToks && (
                          <Box
                            sx={{
                              mt: 2,
                              pt: 2,
                              borderTop: "1px solid #eaeaea",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                color: "#64748b",
                                mb: 1,
                                fontFamily: "monospace",
                              }}
                            >
                              <LinkIcon fontSize="small" />
                              Related TikToks
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                              }}
                            >
                              {product.relatedTikToks.map((tiktok) => (
                                <Box
                                  key={tiktok.id}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    p: 1,
                                    borderRadius: "8px",
                                    backgroundColor: "#f8fafc",
                                    "&:hover": {
                                      backgroundColor: "#f1f5f9",
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 500,
                                        fontFamily: "monospace",
                                      }}
                                    >
                                      {tiktok.title}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      sx={{ fontFamily: "monospace" }}
                                    >
                                      {tiktok.creator} • {tiktok.views} views
                                    </Typography>
                                  </Box>
                                  <Link
                                    href={tiktok.link}
                                    target="_blank"
                                    rel="noopener"
                                  >
                                    <IconButton size="small">
                                      <LaunchIcon
                                        fontSize="small"
                                        sx={{ color: "#2563eb" }}
                                      />
                                    </IconButton>
                                  </Link>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PulseDemoPage;
