import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

function PulsePage() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#f8fafc",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "auto",
        width: "100%",
      }}
    >
      <Box
        sx={{
          mx: "auto",
          px: { xs: 2, sm: 3, md: 4 },
          py: { xs: 4, sm: 6, md: 8 },
          fontFamily: "monospace",
        }}
      >
        <Box sx={{ mb: 6, textAlign: "center" }}>
          <Typography
            variant="h1"
            className="gradient-text"
            sx={{
              fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
              fontWeight: 700,
              mb: 3,
              fontFamily: "Helvetica, sans-serif",
            }}
          >
            Predict Viral Trends
          </Typography>

          <Typography
            variant="h2"
            sx={{
              color: "#64748b",
              fontSize: { xs: "1.1rem", sm: "1.25rem", md: "1.5rem" },
              mb: 4,
              maxWidth: "800px",
              mx: "auto",
              lineHeight: 1.6,
              fontFamily: "monospace",
            }}
          >
            Leading AI-powered analysis of social media video content to predict
            trending products before they go viral. Beyond hashtags and likes -
            we understand what's actually in the videos.
          </Typography>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/contact")}
              sx={{
                backgroundColor: "#2563eb",
                fontFamily: "monospace",
                fontSize: "1.1rem",
                py: 1.5,
                px: 4,
                "&:hover": {
                  backgroundColor: "#1d4ed8",
                },
              }}
            >
              Interested in learning more?
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
            gap: 4,
            mb: 6,
          }}
        >
          <Box sx={{ textAlign: "center", p: 3 }}>
            <PlayCircleOutlineIcon
              sx={{ fontSize: 48, color: "#2563eb", mb: 2 }}
            />
            <Typography
              variant="h6"
              sx={{ mb: 2, fontFamily: "Helvetica, sans-serif" }}
            >
              Video Content Analysis
            </Typography>
            <Typography sx={{ color: "#64748b", fontFamily: "monospace" }}>
              Our AI understands products, context, and trends within{" "}
              <strong>TikTok</strong>, <strong>Instagram Reels</strong>,{" "}
              <strong>YouTube Shorts</strong>, and more.
            </Typography>
          </Box>

          <Box sx={{ textAlign: "center", p: 3 }}>
            <TrendingUpIcon sx={{ fontSize: 48, color: "#2563eb", mb: 2 }} />
            <Typography
              variant="h6"
              sx={{ mb: 2, fontFamily: "Helvetica, sans-serif" }}
            >
              Early Trend Detection
            </Typography>
            <Typography sx={{ color: "#64748b", fontFamily: "monospace" }}>
              Spot viral trends before they explode and capitalize on early
              opportunities
            </Typography>
          </Box>

          <Box sx={{ textAlign: "center", p: 3 }}>
            <ShoppingBagIcon sx={{ fontSize: 48, color: "#2563eb", mb: 2 }} />
            <Typography
              variant="h6"
              sx={{ mb: 2, fontFamily: "Helvetica, sans-serif" }}
            >
              Product Opportunities
            </Typography>
            <Typography sx={{ color: "#64748b", fontFamily: "monospace" }}>
              Turn social insights into product recommendations and business
              decisions
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: "center",
            mt: 8,
            pt: 4,
            borderTop: "1px solid #eaeaea",
          }}
        >
          <Typography
            sx={{
              color: "#64748b",
              fontFamily: "monospace",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            Backed by
            <img
              src="/yc-logo.png"
              height={25}
              width={25}
              alt="YC Logo"
              style={{ marginLeft: 8 }}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default PulsePage;
