import React from "react";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <>
      <header>
        <h1>exla</h1>
      </header>
      <div className="navbar">
        <nav>
          <Link to="/" className="nav-link">
            Home
          </Link>
          <Link to="/docs" className="nav-link">
            Docs
          </Link>
          <Link to="/contact" className="nav-link">
            Contact
          </Link>
        </nav>
      </div>
    </>
  );
}

export default NavBar;
