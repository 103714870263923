import React from "react";

function DocsPage() {
  return (
    <div>
      <section id="docs-header">
        <h3>Get started with Exla in minutes</h3>
        <p>
          <i>
            Note: Exla is currently in private beta. Please contact us at{" "}
            <a href="mailto:contact@exla.ai">contact@exla.ai</a> to get started.
          </i>
        </p>
      </section>

      <div>
        <h3>Installation</h3>
        <div>
          <pre>pip install exla</pre>
        </div>
      </div>

      <div>
        <h3>Basic Usage</h3>
        <div>
          <pre>{`import exla

# Load your own model from Hugging Face, etc.
model = ...

# Hardware-specific optimization
optimized_model = exla.optimize(
    model,
    target="nvidia-a100",     # or "apple-m1", "nvidia-jetson-orin-nano", etc.
    memory_budget="8GB"       # optional memory constraint
)
    
# Yes, it's that easy!`}</pre>
        </div>
      </div>

      <div>
        <h3>Hardware Support</h3>
        <div>
          <pre>{`Mobile:
  • iPhone (iOS 15+)
  • Android devices (API 30+)

Desktop:
  • Apple Silicon (M1/M2/M3)
  • Intel CPUs (x86_64)
  • AMD CPUs (x86_64)

GPU:
  • NVIDIA Consumer (RTX 20/30/40 series)
  • NVIDIA Data Center (A100, H100)
  • Apple Metal (integrated GPU)

Edge:
  • NVIDIA Jetson`}</pre>
        </div>
      </div>
    </div>
  );
}

export default DocsPage;
