import React from "react";
import { Button } from "@mui/material";

const ExlaStorePage = () => {
  return (
    <div className="store-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>
            Exla Store
            <span className="gradient-text"> Managed Storage Service</span>
          </h1>
          <p className="hero-subtitle">
            A fully managed storage solution that combines the simplicity of S3
            with powerful LLM-based search capabilities. Built on top of our
            battle-tested Exla SDK.
          </p>

          <div className="cta-container" align="center">
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                window.open(
                  "https://tally.so/r/wdKkNy",
                  "_blank",
                  "noopener noreferrer"
                )
              }
              sx={{
                background: "linear-gradient(90deg, #2563eb, #4f46e5)",
                fontSize: "1rem",
                fontWeight: "bold",
                padding: "12px 32px",
                "&:hover": {
                  background: "linear-gradient(90deg, #1d4ed8, #4338ca)",
                },
              }}
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <h2>Why Choose Exla Store?</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Zero Setup Time</h3>
            <p>
              Get started immediately with our managed service. No
              infrastructure setup, no configuration headaches.
            </p>
          </div>
          <div className="feature-card">
            <h3>Enterprise-Grade Security</h3>
            <p>
              Built-in encryption, access controls, and compliance features to
              keep your data secure and compliant.
            </p>
          </div>
          <div className="feature-card">
            <h3>Predictable Pricing</h3>
            <p>
              Simple, transparent pricing based on storage and usage. No hidden
              fees or surprising bills.
            </p>
          </div>
          <div className="feature-card">
            <h3>24/7 Support</h3>
            <p>
              Expert support team available around the clock to help you succeed
              with Exla Store.
            </p>
          </div>
        </div>
      </section>

      {/* Integration Section */}
      <section className="integration-section">
        <h2 align="left">Built on Exla SDK</h2>
        <p align="left">
          Exla Store is powered by the same enterprise-grade SDK that powers our
          self-hosted solutions. Get all the benefits of our proven technology
          without the operational overhead.
        </p>
        <div className="sdk-features">
          <ul>
            <li>Advanced LLM-powered search capabilities</li>
            <li>Automatic content processing and indexing</li>
            <li>S3-compatible API</li>
            <li>Enterprise-grade security and compliance</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default ExlaStorePage;
