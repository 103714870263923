import React, { useEffect } from "react";
import Cal, { getCalApi } from "@calcom/embed-react";

const ContactPage = () => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", { hideEventTypeDetails: false, layout: "month_view" });
    })();
  }, []);

  return (
    <div style={{ fontSize: "15px" }}>
      <section id="contact-info">
        <p>Interested in Exla for your organization?</p>
        <a href="mailto:contact@exla.ai">contact@exla.ai</a>
      </section>

      <section id="contact-info" align="center">
        <h3>Talk to us</h3>
      </section>

      <section
        id="calendar"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Cal
          namespace="30min"
          calLink="exla-ai/30min"
          style={{ width: "75%", height: "75%", overflow: "scroll" }}
          config={{ layout: "month_view", theme: "light" }}
        />
      </section>
    </div>
  );
};

export default ContactPage;
