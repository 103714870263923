import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Mock data for demonstration
const mockSensorData = Array.from({ length: 24 }, (_, i) => ({
  time: `${i}:00`,
  temperature: 85 + Math.random() * 10,
  vibration: 2.5 + Math.random(),
  pressure: 50 + Math.random() * 5,
  riskScore: Math.random(),
}));

const systemStatus = {
  "Cooling Pump A": "Healthy",
  "Steam Generator": "Warning",
  "Main Turbine": "Critical",
  "Feedwater System": "Healthy",
};

function NuclearPage() {
  const [selectedSystem, setSelectedSystem] = useState("Cooling Pump A");

  return (
    <div className="nuclear-dashboard">
      {/* Header Section */}
      <section id="docs-header">
        <h1>Nuclear Power Plant AI Dashboard (Demo)</h1>
        <p>
          <b>
            Revolutionizing nuclear power with AI-powered predictive maintenance
          </b>
          . Save millions annually by optimizing operations, reducing downtime,
          and improving safety.
        </p>
        <p>
          <i>
            Our platform dynamically identifies maintenance needs, predicts
            equipment failures, and enables real-time decisions to maximize fuel
            efficiency and reduce operational costs.
          </i>
        </p>
      </section>

      <div className="dashboard-grid">
        {/* System Status Panel */}
        <div className="dashboard-panel">
          <h2>System Health Overview</h2>
          <p>
            Track the health of critical systems in real time. Click on a system
            to view detailed analytics.
          </p>
          <div className="status-grid">
            {Object.entries(systemStatus).map(([system, status]) => (
              <div
                key={system}
                className={`status-card ${status.toLowerCase()}`}
                onClick={() => setSelectedSystem(system)}
              >
                <h4>{system}</h4>
                <span className={`status-badge ${status.toLowerCase()}`}>
                  {status}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Real-Time Monitoring Panel */}
        <div className="dashboard-panel">
          <h2>Real-Time Monitoring: {selectedSystem}</h2>
          <p>
            Live insights from sensors to monitor temperature, vibration, and
            pressure, ensuring early detection of anomalies.
          </p>
          <div style={{ height: "300px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={mockSensorData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="temperature"
                  stroke="#8884d8"
                  name="Temperature (°C)"
                />
                <Line
                  type="monotone"
                  dataKey="vibration"
                  stroke="#82ca9d"
                  name="Vibration (mm/s)"
                />
                <Line
                  type="monotone"
                  dataKey="pressure"
                  stroke="#ffc658"
                  name="Pressure (PSI)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Maintenance Recommendations */}
        <div className="dashboard-panel">
          <h2>Maintenance Insights</h2>
          <p>
            AI-generated recommendations to prevent failures, optimize downtime,
            and reduce costs. Saving millions with early intervention.
          </p>
          <div className="recommendations-list">
            <div className="recommendation urgent">
              <span className="priority">🚨 High Priority</span>
              <h4>Main Turbine Bearing Replacement</h4>
              <p>
                **Excessive vibration detected.** Predictive analysis indicates
                a 95% likelihood of failure within 48 hours. Immediate
                replacement recommended to avoid $3M in downtime costs.
              </p>
            </div>
            <div className="recommendation warning">
              <span className="priority">⚠️ Medium Priority</span>
              <h4>Steam Generator Inspection</h4>
              <p>
                **Pressure fluctuations observed.** Predicted risk of minor
                damage if not addressed within 7 days. Plan inspection during
                next routine downtime.
              </p>
            </div>
            <div className="recommendation normal">
              <span className="priority">🔵 Routine</span>
              <h4>Cooling Pump A Maintenance</h4>
              <p>
                **Normal performance detected.** Routine maintenance due in 30
                days as per schedule.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Value Proposition Section */}
      <section id="value-prop">
        <h2>Why This Platform Matters</h2>
        <ul>
          <li>
            **Save Millions Annually**: Early failure detection reduces
            unplanned downtime, avoiding costs of up to $2M/day.
          </li>
          <li>
            **Extend Equipment Lifespan**: Predictive analytics optimize
            maintenance schedules, reducing wear and tear.
          </li>
          <li>
            **Promote Nuclear Power**: By lowering operational costs, this
            platform makes nuclear energy more economically viable, enabling
            broader adoption.
          </li>
          <li>
            **Improve Safety**: AI continuously monitors critical systems,
            ensuring compliance with safety margins and regulatory standards.
          </li>
        </ul>
      </section>
    </div>
  );
}

export default NuclearPage;
